/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AttributionsByMonth } from '../models';
// @ts-ignore
import type { Earnings } from '../models';
/**
 * AffiliatesPortalApi - axios parameter creator
 * @export
 */
export const AffiliatesPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this affiliate user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve', 'id', id)
            const localVarPath = `/wt/affiliates-portal/api/affiliate-profile/{id}/attributions_by_month/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this affiliate user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesPortalApiAffiliateProfileEarningsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliatesPortalApiAffiliateProfileEarningsRetrieve', 'id', id)
            const localVarPath = `/wt/affiliates-portal/api/affiliate-profile/{id}/earnings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliatesPortalApi - functional programming interface
 * @export
 */
export const AffiliatesPortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AffiliatesPortalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this affiliate user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributionsByMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AffiliatesPortalApi.affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this affiliate user profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliatesPortalApiAffiliateProfileEarningsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Earnings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliatesPortalApiAffiliateProfileEarningsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AffiliatesPortalApi.affiliatesPortalApiAffiliateProfileEarningsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AffiliatesPortalApi - factory interface
 * @export
 */
export const AffiliatesPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AffiliatesPortalApiFp(configuration)
    return {
        /**
         * 
         * @param {AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(requestParameters: AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<AttributionsByMonth> {
            return localVarFp.affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliatesPortalApiAffiliateProfileEarningsRetrieve(requestParameters: AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Earnings> {
            return localVarFp.affiliatesPortalApiAffiliateProfileEarningsRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve operation in AffiliatesPortalApi.
 * @export
 * @interface AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest
 */
export interface AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest {
    /**
     * A unique integer value identifying this affiliate user profile.
     * @type {number}
     * @memberof AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for affiliatesPortalApiAffiliateProfileEarningsRetrieve operation in AffiliatesPortalApi.
 * @export
 * @interface AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest
 */
export interface AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest {
    /**
     * A unique integer value identifying this affiliate user profile.
     * @type {number}
     * @memberof AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieve
     */
    readonly id: number
}

/**
 * AffiliatesPortalApi - object-oriented interface
 * @export
 * @class AffiliatesPortalApi
 * @extends {BaseAPI}
 */
export class AffiliatesPortalApi extends BaseAPI {
    /**
     * 
     * @param {AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliatesPortalApi
     */
    public affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(requestParameters: AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieveRequest, options?: RawAxiosRequestConfig) {
        return AffiliatesPortalApiFp(this.configuration).affiliatesPortalApiAffiliateProfileAttributionsByMonthRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliatesPortalApi
     */
    public affiliatesPortalApiAffiliateProfileEarningsRetrieve(requestParameters: AffiliatesPortalApiAffiliatesPortalApiAffiliateProfileEarningsRetrieveRequest, options?: RawAxiosRequestConfig) {
        return AffiliatesPortalApiFp(this.configuration).affiliatesPortalApiAffiliateProfileEarningsRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

